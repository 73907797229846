<template>
  <div class="page-wrapper" :key="actualZq" v-if="actualZq">
    <div class="title-content">
      <bread-crumb>
        <el-breadcrumb-item :to="{ name: 'homePage' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>风险检测流程 </el-breadcrumb-item>
        <el-breadcrumb-item v-if="dataPeriod != 3"
          ><span class="changeUserInfo1">申报数据采集</span></el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ zqName }}</el-breadcrumb-item>
      </bread-crumb>

      <div class="main-top">
        <main-header>
          <template slot="title">{{ dataPeriod == 3 ? "数据读取" : "数据补充" }} </template>
        </main-header>
        <template>
          <div class="tips" v-if="dataPeriod == 3 && version == 11">
            您可以选择
            <span class="tip-span" @click="toUpload">跳过</span>
            此步骤，手动提交报表
          </div>
        </template>
        <div
          class="baseVersion"
          @click="addData"
          v-if="fdcVersionList.indexOf(Number(version)) > -1"
        >
          基础信息表单
        </div>
      </div>
      <diqu></diqu>
    </div>
    <div class="congtent-title-tab" v-if="dataPeriod != 3">数据补充</div>
    <div class="el-alert-warning" v-if="dataPeriod != 3 && warningText">
      <div class="el-alert-warningText">
        为了检测结果更加全面准确，请上传本期和上期的{{ warningText }}
      </div>
    </div>
    <div class="content-body-tab" v-if="dataPeriod != 3">
      <div v-if="dataPeriod == 1">
        <router-link
          :to="{
            name: 'upload-table',
            params: {
              zq,
              zqLocation,
              dataPeriod: 0,
            },
            query: {
              nsrsbhIndex: this.nsrsbhIndex,
              info: this.$route.query.info,
              available: this.$route.query.available,
              thirdDataSource: this.$route.query.thirdDataSource,
              taxRateCalculation: this.$route.query.taxRateCalculation,
              taxfirmTag: this.$route.query.taxfirmTag,
              qykjzdIndex: this.qykjzdIndex,
            },
          }"
        >
          <div class="content-body-tabl" :class="{ zqLocation: dataPeriod == 0 }">
            {{ zq.substring(0, 4) }}年报表
          </div>
        </router-link>
      </div>
      <div
        v-if="dataPeriod == 0"
        class="content-body-tabl"
        :class="{ zqLocation: dataPeriod == 0 }"
      >
        {{ zq.substring(0, 4) }}年报表
      </div>
      <div
        class="content-body-tabr"
        :class="{ zqLocation: dataPeriod == 1 }"
        @click="handleBtnNext"
        v-if="dataPeriod == 0"
      >
        {{ zq.substring(0, 4) - 1 }}年报表<span v-if="dataPeriod == 0">建议上传</span>
      </div>
      <div
        class="content-body-tabr"
        :class="{ zqLocation: dataPeriod == 1 }"
        v-if="dataPeriod == 1"
      >
        {{ zq.substring(0, 4) - 1 }}年报表
      </div>
    </div>

    <div class="content-body" :class="{ nomargin: dataPeriod == 3 }">
      <div v-if="dataPeriod != 3" :class="{ uploadFlex: showTableDetail }">
        <table-list
          v-show="showTableDetail"
          :tables="tables"
          @listChanged="listChanged"
          manner="upload"
          class="table-list"
          :class="{ uploadFlexRight: showTableDetail }"
          @showDetailBalance="showDetailBalance"
          :nsrsbhIndex="nsrsbhIndex"
        ></table-list>
        <div :class="{ uploadFlexLeft: showTableDetail }">
          <div
            class="content-body-flex"
            :class="{ uploadFlexLefts: showTableDetail }"
            v-if="dataPeriod != 3"
          >
            <div class="content-top-1" v-if="!showTableDetail">
              <span v-if="dataPeriod == 0">{{ zq.substring(0, 4) }}年报表上传</span>
              <span v-if="dataPeriod == 1">{{ zq.substring(0, 4) - 1 }}年报表上传</span>
            </div>
            <div class="content-body-flexr">
              <div class="acknowlege content-top-2" @click="acknowledgeDialogVisible = true">
                查看上传须知
              </div>
              <a
                href="//shuian-file.oss-cn-hangzhou.aliyuncs.com/user/%E6%8A%A5%E8%A1%A8%E6%A8%A1%E6%9D%BF.zip"
                class="btn-download content-top-2"
                download
                >下载模板</a
              >
            </div>
          </div>
          <div>
            <uploader
              :nsrsbhIndex="nsrsbhIndex"
              @onUpload="onUpload"
              :uuid="uuid"
              :showTableDetail="showTableDetail"
              v-if="actualZq"
              @showDetailBox="showDetailBox"
              @changeLength="changeLength"
              @overUpload="overUpload"
            ></uploader>
          </div>

          <div class="arrow-icon" v-if="!showTableDetail" @click="showMoreUpload">
            手动填写报表
            <i class="el-icon-d-arrow-left"></i>
          </div>
        </div>
      </div>

      <template v-if="dataPeriod == 3">
        <!-- <report-method
          @openlicense="openlicense"
          @setName="changeName"
          ref="showReport"
          v-if="!isTaxAutoProcessing"
          :zq="zq"
          :uuid="uuid"
          :nsrsbhIndex="nsrsbhIndex"
        ></report-method> -->
        <!-- 自动取数iframe -->
        <div v-if="isTaxAutoProcessing == 1">
          <iframe class="fetchBaseUrl" :src="fetchBaseUrl"></iframe>
        </div>
        <div v-else>
          <div class="data-loading-wrapper">
            <img src="@/assets/test/data-loading.png" style="width: 465px" />
            <div v-if="isTaxAutoProcessing == 2" class="text">当前项目已授权，点击下一步</div>
            <div v-if="isTaxAutoProcessing == 3" class="text">当前税号已授权</div>
          </div>
        </div>
      </template>

      <sa-footer v-if="dataPeriod != 3">
        <div class="footer">
          <button
            class="btn btn-purge-data"
            @click="handleBtnPurgeDataClicker"
            v-if="dataPeriod != 3"
          >
            清除数据
          </button>
          <!-- <button
            class="btn btn-default btn-next btn-right-none ease"
            @click="changeUserInfo1"
          >
            上一步
          </button> -->
          <button @click="handleBtnNext2" class="btn-primary new-next-btn ease new-next-btn360">
            提交
          </button>
        </div>
      </sa-footer>
      <sa-footer v-if="dataPeriod == 3">
        <button
          @click="handleBtnNextAutoHome"
          class="btn-primary new-next-btn new-next-btn360 ease"
          v-if="isTaxAutoProcessing == 3"
        >
          返回首页
        </button>
        <button
          @click="toUpload"
          class="btn-primary new-next-btn new-next-btn360 ease"
          v-if="isTaxAutoProcessing == 2"
        >
          下一步
        </button>
      </sa-footer>
    </div>
    <!--温馨提示弹出框-->
    <el-dialog
      :visible.sync="tipDialogVisible"
      width="576px"
      :show-close="false"
      class="tipDialog"
      top="32vh"
    >
      <div class="inner">
        <img src="@/assets/test/tip.png" class="img" />
        <div class="right-wrapper">
          <div class="title">温馨提示</div>
          <div class="desc">
            为保证数据准确提取，需尽量保证文件命名标准，若一个Excel内包含多个sheet则sheet页命名需规范
          </div>
          <button class="btn btn-primary btn-i-get" @click="handleTipDialogBtnClick">知道了</button>
        </div>
      </div>
    </el-dialog>
    <!--温馨提示弹出框-->
    <!--上传错误提示弹出框-->
    <el-dialog
      :visible.sync="correctDialogVisible"
      width="80%"
      :show-close="true"
      class="correctDialog"
      top="1vh"
      @close="handleCorrectDialogClose"
    >
      <SectionTitle>报表匹配</SectionTitle>
      <div class="modify-table-wrapper">
        <modify-table v-if="correctDialogVisible"></modify-table>
      </div>
    </el-dialog>
    <!--/上传错误提示弹出框-->
    <!--纠错弹出框-->
    <el-dialog
      :visible.sync="uploadErrorDialogVisible"
      width="800px"
      :show-close="true"
      top="25vh"
      title="抱歉,以下表无法解析"
    >
      <el-table :data="uploadErrors" style="width: 100%">
        <el-table-column prop="userFileName" label="表名"></el-table-column>
        <el-table-column prop="reason" label="异常原因"></el-table-column>
      </el-table>
    </el-dialog>
    <!--/纠错弹出框-->
    <!--上传须知提示弹出框-->
    <el-dialog
      :visible.sync="acknowledgeDialogVisible"
      width="62.5%"
      :show-close="true"
      class="correctDialog"
    >
      <SectionTitle>上传须知</SectionTitle>
      <div class="modify-table-wrapper">
        <acknowledage-tab
          v-on:acknowledgeDialogVisible="clickbtn"
          :bankId="bankId"
        ></acknowledage-tab>
      </div>
    </el-dialog>
    <!--/上传须知提示弹出框-->

    <!--数据上传组件-->
    <TestDataUploader
      ref="testDataUploader"
      :bankId="bankId"
      :productID="productID"
      :productName="productName"
      :uuid="uuid"
      :zq="zq"
      :version="version"
    ></TestDataUploader>
    <!--/数据上传组件-->

    <loading
      :active.sync="isLoading"
      backgroundColor="#000"
      :can-cancel="true"
      :is-full-page="fullPage"
      loader="Dots"
      color="#2f54eb"
    ></loading>

    <!--所得税填报选择-->
    <el-dialog
      :visible.sync="showSDSDialog"
      :close-on-click-modal="false"
      width="800px"
      :show-close="false"
      class="correctDialog"
    >
      <SectionTitle>所得税填报选择</SectionTitle>
      <SDSFilter :tables="SDStables" @onDialogSubmit="handleSDSDialogSubmit"></SDSFilter>
    </el-dialog>
    <!--/所得税填报选择-->
    <licen ref="opendialog"></licen>
    <div v-if="showTables" class="containertables">
      <div class="tables">
        <div class="title">{{ balanceName }}</div>
        <div class="closes" @click="deleteTable">×</div>
        <div class="content">
          <el-table
            :data="tableData"
            style="width: 100%"
            height="620"
            row-key="subBsDm"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="subBsDm" label="科目编码" width="160"></el-table-column>
            <el-table-column prop="subBsMc" label="科目名称" width="150"></el-table-column>
            <el-table-column label="年初余额" align="center">
              <el-table-column
                prop="ncBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="ncLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="期初余额" align="center">
              <el-table-column
                prop="beforeBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="beforeLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="本期发生额" align="center">
              <el-table-column
                prop="currentBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="currentLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="本年累计" align="center">
              <el-table-column
                prop="bnljBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="bnljLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="期末余额" align="center">
              <el-table-column
                prop="afterBorrow"
                label="借方"
                align="right"
                width="140"
              ></el-table-column>
              <el-table-column
                prop="afterLoan"
                label="贷方"
                align="right"
                width="140"
              ></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="progressUpload" v-if="progressUpload">
      <div class="progressBox">
        <img class="undraw_upload" src="@/assets/result/undraw_upload_87y9@2x.png" alt />
        <el-progress
          type="circle"
          :percentage="progressUploadStatus"
          :width="286"
          :status="processTexted ? 'success' : null"
        ></el-progress>
        <div class="processText" v-if="processTexting">上传中...</div>
        <div class="processTexted processTextedNo" v-if="processTexted">上传成功</div>
      </div>
    </div>
    <fangdichan ref="fangdiChan" types="2"></fangdichan>
  </div>
</template>

<script>
import {
  breezeOuterAi,
  addReportValue,
  findTaxAutoStatusByUuid,
  getInfoByCompanyNsrsbm,
  zjBreezeLoginCreateTaskBj,
  //
  newShanxiBreeze,
  zjBreezeLoginV2,
  getAuthStatus,
  getAuthStatus2,
} from "@/api/api";
import { cloneDeep } from "lodash";
import BreadCrumb from "@/components/BreadCrumb";
import Uploader from "./components/Uploader";
import TableList from "./components/TableList";
import ModifyTable from "./components/ModifyTable";
import SectionTitle from "@/components/SectionTitle";
import AcknowledageTab from "./components/AcknowledageTab";
import SDSFilter from "./components/SDSFilter";
import MainHeader from "@/pages/components/MainHeader";
import ProgressDiagram from "@/pages/components/ProgressDiagram";
import Footer from "../ChooseTable/components/Footer";
import TestDataUploader from "../Components/TestDataUploader";
import { getUserTables, clearUploadedTableforTest } from "@/api/table";

import fangdichan from "./components/fangdichan";

import {
  clearUploadedTableforTestAi,
  useAuthorizeAi,
  genTestReportAi,
  findNeedExcel2,
  findNeedExcel3,
  zjBreezeLoginCreateTask,
} from "@/api/api";

import { loadTableData } from "@/api/table";

import { mapState, mapMutations } from "vuex";
import { zq2name, previousZq } from "@/utils/helper";
import reportMethod from "../../Test/ChooseTable/components/report-method";
import licen from "./components/licen-dialog";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

import diqu from "../../Test/ChooseTable/components/diqu";

export default {
  data() {
    return {
      fdcVersionList: [12, 14, 21],
      //
      checkBaseInfoData: "",
      baseVersion: 12, //房地产tag 需要基础信息表
      baseVersion21: 21, //房地产2.0tag 需要基础信息表
      fangdichanDialogVisible: false,
      warningText: "",
      isTaxAutoProcessing: 1,
      processTexting: true,
      processTexted: false,
      progressUpload: false,
      progressUploadStatus: "0",
      balanceName: "",
      showTables: false,
      tableData: [],
      tables: {},
      tipDialogVisible: false, //刚开始进入的提示框
      fullPage: true,
      isLoading: false,
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      templateDownloadURL: "",
      // hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      index: -1,
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      showTableDetail: true,
      batchNo: "",
      taxSiteUsername: "",
      //
      fetchBaseUrl: "",
      companyNameInfo: JSON.parse(this.$route.query.info).consumerEntity,
      nsrsbhIndex: this.$route.query.nsrsbhIndex,
      postMsg: 100,
    };
  },
  components: {
    Loading,
    BreadCrumb,
    Uploader,
    TableList,
    SectionTitle,
    ModifyTable,
    AcknowledageTab,
    MainHeader,
    ProgressDiagram,
    saFooter: Footer,
    TestDataUploader,
    SDSFilter,
    reportMethod,
    licen,
    fangdichan,
    diqu,
  },
  props: {
    zq: [Number, String],
    productName: String,
    productID: [Number, String],
    zqLocation: [Number, String],
    bankId: String,
    uuid: String,
    dataPeriod: [Number, String],
    version: [Number, String],
    // nsrsbhIndex: [Number, String],
    qykjzdIndex: [Number, String],
    selectvalueIndex: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),
    correctDialogVisible: {
      get: function () {
        return this.$store.state.UploadTable.correctDialogVisible;
      },
      set: function (value) {
        this.setCorrectDialogVisible(value);
      },
    },
    zqName: function () {
      return zq2name(this.actualZq);
    },
    progress: function () {
      let ret = 2;
      if (this.dataPeriod == 0) {
        ret = 2;
      } else if (this.dataPeriod == 1) {
        ret = 3;
      }
      return ret;
    },
    SDStables() {
      let ret = [];
      if (this.tables.所得税表) {
        ret = this.tables.所得税表.list;
      }
      return ret;
    },
  },
  async mounted() {
    await this.init();
    let nsrsbm = this.nsrsbhIndex;
    // 判断是不是正在取数中

    // 基础信息表
    // if (!sessionStorage.getItem("isShowBase")) {
    this.showBaseInfo();
    // }

    this.clearBrowserNavigatorHistory();
    //
    //
    this.fetchUrl();
    //
    window.addEventListener("message", (e) => {
      if (e.data == "v2-submit-success" && this.dataPeriod == 3) {
        // console.log("123调用几次")
        // this.getFindNeedExcel3();
        // this.toUpload();
        this.postMsg = 101;
        // if (res.data) {
        //   sessionStorage.setItem("taxSiteUsernameTaxNo", res.data.nsrsbh);
        // }
      }
    });
    // 轮询
    if (this.dataPeriod == 3) {
      this.checkTimer = window.setInterval(() => {
        setTimeout(this.checkAuto(), 0);
        // 每三分钟执行 6000*10 * 3
      }, 10000);
    }

    //
    if (this.fdcVersionList.indexOf(Number(this.version)) > -1) {
      this.addData();
    }
  },
  methods: {
    addData() {
      this.$refs.fangdiChan.getBaseInfo();
    },
    // 轮询方法
    checkAuto() {
      getAuthStatus2(this.uuid).then((res) => {
        if (res.code == 200) {
          let authorized = res.data;
          if (authorized) {
            window.clearInterval(this.checkTimer);
            this.postMsg = 101;
          }
        }
      });
    },
    clearBrowserNavigatorHistory() {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", function () {
        history.pushState(null, null, document.URL);
      });
    },
    //
    fetchUrl() {
      let baseEnv = process.env.VUE_APP_PDF_ROOT_FETCH;
      let info = JSON.parse(this.$route.query.info).consumerEntity;
      let data = {
        companyName: info.companyName,
        companyNo: this.$route.query.nsrsbhIndex,
        companyTaxNo: info.nsrsbh,
        hydm: info.industry,
        preInspectId: this.uuid,
      };
      this.fetchBaseUrl =
        baseEnv +
        "oemV2/" +
        this.zq +
        "/auth?version=2&info=" +
        JSON.stringify(data) +
        "&from=shiwusuo";
    },
    toUpload() {
      this.$router.push({
        name: "upload-table",
        params: {
          zq: this.zq,
          zqLocation: this.zqLocation,
          dataPeriod: 0,
          // nsrsbhIndex: this.nsrsbhIndex,
          // qykjzdIndex: this.qykjzdIndex,
        },
        query: {
          nsrsbhIndex: this.nsrsbhIndex,
          info: this.$route.query.info,
          available: this.$route.query.available,
          thirdDataSource: this.$route.query.thirdDataSource,
          taxRateCalculation: this.$route.query.taxRateCalculation,
          taxfirmTag: this.$route.query.taxfirmTag,
        },
      });
      this.clearBrowserNavigatorHistory();
    },
    //
    showBaseInfo() {
      if (this.fdcVersionList.indexOf(Number(this.version)) > -1) {
        this.fangdichanDialogVisible = true;
      }
    },
    closeBase() {
      sessionStorage.setItem("isShowBase", 100);
      this.fangdichanDialogVisible = false;
    },
    cancelBaseinfo() {
      sessionStorage.setItem("isShowBase", 100);
      this.fangdichanDialogVisible = false;
    },
    getFindNeedExcel3() {
      let params = {
        uuid: this.uuid,
        tag: this.version,
        zq: this.zq,
        nsrsbm: this.nsrsbhIndex,
        taxNo: JSON.parse(this.$route.query.info).consumerEntity.nsrsbh,
      };
      findNeedExcel3(params).then((res) => {
        this.warningText = res.data;
      });
    },
    checkOld() {
      let taxNo = "";
      let info = JSON.parse(this.$route.query.info).consumerEntity;
      if (this.$route.query.available) {
        taxNo = info.nsrsbh;
      } else {
        taxNo = "";
      }
      const loading = this.$loading({
        lock: true,
        text: "正在提交数据！",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      genTestReportAi({
        token: localStorage.getItem("authorization"),
        nsrsbh: this.nsrsbhIndex,
        tag: this.version,
        uuid: this.uuid,
        zq: this.zq,
        invoiceNsrsbh: taxNo,
        isAutoData: 1,
        getDataWay: this.$route.query.available ? 2 : 1, //授权过就传2(自动)
        taxRateCalculation: this.$route.query.taxRateCalculation,
        deleteDataType: this.$route.query.deleteDataType,
      }).then((res) => {
        if (res.msgCode == 1) {
          loading.close();
          this.$router.push({
            name: "test-InvoiceUpload",
            params: {
              uuid: this.uuid,
            },
          });
        } else if (res.msgCode == -1) {
          loading.close();
          //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
          this.$alert(res.msgInfo, "错误", {
            confirmButtonText: "确定",
            type: "error",
            callback: (action) => {
              this.$router.push({ name: "homePage" });
            },
          });
        } else {
          loading.close();
          this.$message({
            message: res.msgInfo,
            type: "error",
            duration: 5000,
            callback: (action) => {
              alert("dasdsa");
            },
          });
        }
      });
    },
    //
    handleBtnNextAutoHome() {
      this.$router.push({ name: "homePage" });
    },
    //
    ...mapMutations("UploadTable", ["setCorrectDialogVisible"]),
    showDetailBox() {
      // this.showTableDetail = true;
    },

    overUpload() {
      // this.openF5Refresh();
      this.progressUploadStatus = 100;
      this.processTexted = true;
      this.processTexting = false;
      setTimeout(() => {
        this.progressUpload = false;
        this.processTexted = false;
        this.processTexting = true;
      }, 1000);
    },

    changeLength(val) {
      // this.stopF5Refresh();
      this.progressUpload = true;
      this.progressUploadStatus = Number(((val.status / val.length) * 100).toFixed(2));
      if (this.progressUploadStatus >= 100) {
        this.progressUploadStatus = 100;
      }
    },

    showDetailBalance(val, zq) {
      this.balanceName = zq.slice(0, 4) + "年余额表";

      this.detailZq = zq;
      this.showTables = true;

      let token = localStorage.getItem("authorization");

      loadTableData(zq, "YEB", token, this.uuid).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
          return;
        }
        this.tableData = res.data.dataJson;
      });
    },
    deleteTable() {
      this.showTables = false;
    },
    showMoreUpload() {
      this.showTableDetail = true;
    },

    // 打开授权协议
    openlicense() {
      this.$refs.opendialog.openDialog();
    },

    handleSDSDialogSubmit() {
      this.showSDSDialog = false;
    },

    checkIfAllFiltedTableFilled() {
      //把所有列表展平
      const tables = [];
      for (const key in this.tables) {
        if (this.tables.hasOwnProperty(key)) {
          const tablesInkey = this.tables[key].list;
          for (const table of tablesInkey) {
            tables.push(table);
          }
        }
      }
      return this.SDSSelection.every((aSDSSelection) => {
        const templateId = aSDSSelection.templateId;
        const found = tables.find((table) => {
          return templateId == table.templateId && table.status != "未上传";
        });
        return !!found;
      });
    },
    //本期的下一步按钮
    handleBtnNext() {
      this.$router.push({
        name: "upload-table",
        params: {
          zq: this.zq,
          zqLocation: this.zqLocation,
          dataPeriod: 1,

          // qykjzdIndex: this.qykjzdIndex,
        },
        query: {
          nsrsbhIndex: this.nsrsbhIndex,
          info: this.$route.query.info,
          available: this.$route.query.available,
          thirdDataSource: this.$route.query.thirdDataSource,
          taxRateCalculation: this.$route.query.taxRateCalculation,
          taxfirmTag: this.$route.query.taxfirmTag,
        },
      });
    },
    //提交
    handleBtnNext2() {
      if (this.fdcVersionList.indexOf(this.version) > -1) {
        let params = {
          nsrsbm: this.nsrsbhIndex,
        };
        getInfoByCompanyNsrsbm(params).then((res) => {
          this.checkBaseInfoData = res.data;
          if (!this.checkBaseInfoData || !this.checkBaseInfoData.projectName) {
            this.fangdichanDialogVisible = true;
          } else {
            this.uploadTableSumbit();
          }
        });
      } else {
        this.uploadTableSumbit();
      }
    },
    // 手动上传的提交
    uploadTableSumbit() {
      let params = {
        uuid: this.uuid,
        tag: this.version,
        zq: this.zq,
        nsrsbm: this.nsrsbhIndex,
        taxNo: JSON.parse(this.$route.query.info).consumerEntity.nsrsbh,
      };
      findNeedExcel2(params).then((res) => {
        if (res.msgCode == -1) {
          this.$confirm(res.msgInfo, "提示", {
            confirmButtonText: "跳过",
            cancelButtonText: "补充数据",
            type: "warning",
          })
            .then(() => {
              this.submitDataResult();
            })
            .catch(() => {});
        } else {
          this.submitDataResult();
        }
      });
    },
    submitDataResult() {
      let params = {
        nsrsbh: this.nsrsbhIndex,
        zq: this.zq,
        uuid: this.uuid,
      };
      addReportValue(params).then((res) => {
        if (res.code == 200) {
          this.checkOld();
        }
      });
    },

    async init() {
      // this.isLoading = true;
      // 3自动取数 0本期 1上期
      if (this.dataPeriod == 1) {
        //上个征期
        this.actualZq = previousZq(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      } else if (this.dataPeriod == 0) {
        //当前征期
        this.actualZq = this.zq;
      } else if (this.dataPeriod == 3) {
        //当前征期
        this.actualZq = this.zq;
        // 自动取数 查看是否取数中
        getAuthStatus(this.companyNameInfo.nsrsbh, this.uuid).then((res) => {
          if (res.code == 200) {
            this.isTaxAutoProcessing = res.data.authorized;
          }
        });
      }

      this.$store.commit("UploadTable/setZq", { zq: this.actualZq });
      this.$store.commit("UploadTable/setZqList", { zq: this.zq });

      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      this.$store.commit("UploadTable/setProduct", {
        name: this.productName,
        id: this.productID,
        bankID: this.bankId,
      });

      //下载模板的连接
      switch (sessionStorage.getItem("qykjzd")) {
        case "qykjzd":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%88%B6%E5%BA%A6.zip";
          break;
        case "qykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
        case "xqykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E5%B0%8F%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
      }

      // setTimeout(() => {
      //   this.isLoading = false;
      // }, 500);
    },
    clickbtn() {
      this.acknowledgeDialogVisible = false;
    },
    handleTipDialogBtnClick() {
      this.tipDialogVisible = false;
      localStorage.setItem("hideWenxinTip", true);
    },
    listChanged() {
      this.loadList();
    },
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker() {
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await clearUploadedTableforTestAi(
          // this.companyInfo.nsrsbm,
          // sessionStorage.getItem("nsrsbh"),
          this.nsrsbhIndex,
          this.actualZq,
          localStorage.getItem("authorization"),
          this.uuid
        );

        if (result.code == 200) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.loadList();
        } else {
          this.$message.error(result.message);
        }
      });
    },
    //修正表格的弹出框
    showCorrectDialog() {
      this.correctDialogVisible = true;
    },
    //错误原因弹出框
    showUploadErrorDialog() {
      this.uploadErrorDialogVisible = true;
    },
    submitData() {
      this.correctDialogVisible = true;
    },
    async loadList() {
      // 利用缓存
      const tables = await getUserTables(
        this.bankId,
        this.version,
        // "qykjzd",
        // sessionStorage.getItem("nsrsbh"),
        this.nsrsbhIndex,
        // "1594002474322",
        this.actualZq,
        this.uuid,
        false
      );

      // if (tables.data && tables.msgCode != -1) {
      //   for (let i in tables.data) {
      //     if (tables.data[i].uploadNum > 0) {
      //       this.showTableDetail = true;
      //     }
      //   }
      // }

      //过滤未上传的表格
      this.tables = {};
      let i = 0;
      for (const grpName in tables.data) {
        i++;
        const grpData = tables.data[grpName];
        const newGrp = [];

        for (const table of grpData.tableInfo) {
          i++;
          //if (table.uploadStatus != "未上传") {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum,
            zq,
            children,
          } = table;

          //整理一下children的格式
          const thechildren = children.map((child) => {
            i++;
            const {
              tableNameCn,
              tableNameEn: templateId,
              uploadStatus: status,
              tableNum,
              zq,
              children,
            } = child;
            return {
              name: `${tableNameCn}`,
              status,
              zq,
              templateId,
              tableNum,
              children,
              id: i,
            };
          });
          i++;
          const item = {
            name: `${tableNameCn}`,
            status,
            zq,
            templateId,
            tableNum,
            id: i,
            children: thechildren,
          };

          newGrp.push(item);
          //}
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = {
            list: newGrp,
            totalNum: grpData.totalNum,
            uploadNum: grpData.uploadNum,
          };
        }
      }
    },
    async onUpload(file, data) {
      //如果有错误的表格则弹出弹出框
      if (data.data.errorData.length > 0) {
        this.uploadErrors = data.data.errorData;
        this.showUploadErrorDialog();
      }

      const groups = data.data.succData;

      //根据上传的excel构建本地对应的数组

      //上传文件后需要纠错的表格信息
      const tables4correction = [];

      for (const groupName in groups) {
        const tables = groups[groupName]; //分组里包含的表

        for (const table of tables) {
          const status = table.uploadStatus;

          const { templateId, sysFileName, userFileName } = table;

          if (status === "待修改") {
            table.data = {};
            table.data.templateId = templateId;
            table.data.groupName = groupName;
            table.data.sysFileName = sysFileName;
            table.data.userFileName = userFileName;

            tables4correction.push(table.data);
          }
        }
      }

      if (tables4correction.length > 0) {
        this.$store.commit("UploadTable/setCorrectionTableData", {
          tables4correctionData: tables4correction,
        });
        this.showCorrectDialog();
      }
      this.loadList();
    },
    handleCorrectDialogClose() {
      this.loadList();
    },
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      this.init();
      // 拿到目标参数 to.query.id 去再次请求数据接口
      //this.loadPageData(to.query.id);
      //this.dataPeriod;
      //debugger;
    },
    // dataPeriod(newdataPeriod, olddataPeriod) {
    //   if (newdataPeriod == 3) {
    //     let nsrsbm = this.nsrsbhIndex;
    //     getAuthStatus(this.companyNameInfo.nsrsbh).then((res) => {
    //       if (res.code == 200) {
    //         this.isTaxAutoProcessing = res.data.authorized;
    //       }
    //     });
    //   }
    // },
    postMsg(newdatapostMsg, olddatapostMsg) {
      // this.getFindNeedExcel3(); 查看表格是否上传
      // this.toUpload(); //去手动上传
      this.checkOld();
      window.clearInterval(this.checkTimer);
    },
  },
  destroyed() {
    window.removeEventListener(
      "popstate",
      () => {
        history.pushState(null, null, document.URL);
      },
      false
    );
    window.clearInterval(this.checkTimer);
  },
};
</script>

<style lang="stylus" scoped>
.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.the-title {
  margin: 30px 30px 30px 0;
}

.content-body {
  margin: 30px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 0 0 8px 8px;
  padding: 20px;
  padding-top: 0;
  margin-top: 0;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 18px;
  padding-right: 24px;
}

.footer {
  position: relative;
  // margin-top: 20px;
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

// >>> .el-dialog__header {
// padding: 0;
// }

// >>> .section-title {
// margin-top: 10px;
// }
.page-wrapper {
  padding-bottom: 84px;
}

.progressdis {
  // position: absolute;
  width: 10%;
  // margin: 24px auto 45px;
  margin-left: 100px;
  margin-top: 2px;
}

.btn-right-none {
  margin-right: 16px !important;
  width: 180px;
  height: 46px;
  // line-height: 44px;
  box-sizing: border-box;
}

// .report-method {
// margin: 20px;
// }
.nomargin {
  margin: 0 30px;
  padding: 0;
  margin-top: 16px;
}

.main-top {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.content-body-flex {
  display: flex;
  align-item: center;
  justify-content: space-between;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  background: rgba(255, 255, 255, 1);
  // padding: 0 20px;
  // margin: 0 20px;
}

.content-body-flexr {
  display: flex;
  align-item: center;
  font-size: 14px;
  color: rgba(47, 84, 235, 1);
}

.content-top-1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.content-top-2 {
  margin-right: 24px;
}

.content-body-tab {
  display: flex;
  justify-content: center;
  background: #fff;
  border-bottom: 1px solid #D9D9D9;
  margin: 0 30px 0 30px;
}

.content-body-tabl, .content-body-tabr {
  width: 174px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  // box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.08);
  // border-radius: 18px 18px 0px 0px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  border: 1px solid #fff;
  border-bottom: none;
}

.content-body-tabl a:hover {
  text-decoration: none;
}

a {
   text-decoration: none;
}

.content-body-tabl {
  margin-right: 24px;
}

.router-link-active {
  text-decoration: none;
}

.zqLocation {
  color: #2F54EB;
  font-weight: 600;
  border: 1px solid #D9D9D9;
  border-radius: 18px 18px 0px 0px;
  border-bottom: none;
}

.arrow-icon {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 28px;
}

.uploadFlex {
  display: flex;
  width: 100%;
}

.uploadFlexLeft {
  width: 25%;
  border-left: 1px solid #d9d9d9;
  padding-left: 32px;
  box-sizing: border-box;
}

.uploadFlexRight {
  width: 75%;
}

.uploadFlexLefts {
  border-bottom: none;
  margin-bottom: 0;
}

.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.containertables {
  // display: fixed;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background: rgba(0, 0, 0, 0.04);
}

.process1 {
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.process2 {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.processLImg {
  position: relative;
  bottom: 1px;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.processContainer {
  display: flex;
  align-item: center;
  position: relative;
  top: 6px;
}

.process-status {
  width: 120px;
  height: 2px;
  background: rgba(217, 217, 217, 1);
  position: relative;
  top: 8px;
  margin: 0 24px;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  position: relative;
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.progressUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2022;
}

.progressBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 638px;
  height: 406px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.processText {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2F54EB;
  line-height: 28px;
  margin-top: 25px;
}

.processTexted {
  color: #52C41A;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 28px;
  margin-top: 25px;
}

>>> .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  top: 80%;
}

.undraw_upload {
  position: absolute;
  width: 180px;
  height: 132px;
  top: 90px;
}

.processTextedNo {
  visibility: hidden;
}

.data-loading-wrapper {
  height: 722px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    margin-top: 47px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
  }
}

.changeUserInfo1 {
  color: #999;
  cursor: pointer;
}

.el-alert-warning {
  padding: 24px 78px;
  background: #fff;
}

.el-alert-warningText {
  height: 52px;
  line-height: 52px;
  background: #FBFBFB;
  padding: 0 16px;
  color: rgba(0,0,0,0.6000);
}

.new-next-btn {
  width: 180px;
  height: 46px;
  line-height: 44px;
  background: #2F54EB;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  border: none;
}

.new-next-btn:hover {
  background: #0f3bec;
  color: #fff;
}

.new-next-btn360 {
  width: 360px;
}

.ease {
  transition: all 0.3s;
}

.congtent-title-tab {
  margin: 0 30px;
  height: 54px;
  line-height: 54px;
  background: #FFFFFF;
  /* box-shadow: inset 0px -1px 0px 0px #D9D9D9; */
  border-radius: 8px 8px 0px 0px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(0,0,0,0.8000);
  padding-left: 24px;
  margin-top: 16px;
}

.baseVersion {
  height: 36px;
  line-height: 36px;
  padding: 0 6px;
  background: #2F54EB;
  margin: 4px 6px 0 0;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.fetchBaseUrl {
  width: 100%;
  height: 676px;
  border: none;
}

.tips {
  position: absolute;
  font-size: 14px;
  color: rgba(0,0,0,0.6);
  left: 192px;
  top: 5px;
}

.tips span {
  color: #0f3bec;
  cursor: pointer;
}
</style>
